<template>
  <div>
    <div class="tw-border-b tw-border-gray-300">
      <div class="tw-flex tw-items-center tw-justify-between tw-px-6 tw-py-5">
        <h3 class="tw-text-2xl tw-capitalize tw-font-medium tw-font-heading">Bills</h3>
      </div>
    </div>
    <div class="tw-py-4 tw-px-3">
      <CompanyBills invoice-type="bill" />
    </div>
  </div>
</template>
<script>
import CompanyBills from '../components/company/CompanyBills.vue';

export default {
  components: {
    CompanyBills,
  },
  data() {
    return {
      selectedTab: 0,
    };
  },
};
</script>
